<template>
  <div>
    <div class="navbar">
      <div class="inner">
        <div class="log">
          <router-link
            :to="'/Home/index'"
            style="color:#d14649; text-decoration: none;"
            >VR-CAMPUS TOUR
          </router-link>
<!--          <el-dropdown @command="yxChange" style=" padding: 0 50px; font-size: 14px; color: #d14649;">-->
<!--            <span class="el-dropdown-link">-->
<!--              热门学校<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </span>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item v-for="(item,index) in schoolsTop" :key="index" :command="item" v-show="index<10">{{item.label}}</el-dropdown-item>-->

<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
<!--          <span style=" padding: 0 50px 0 0; font-size: 14px;">-->
<!--            <a style="color: #d14649;" href="https://mp.weixin.qq.com/s/NWyC8FQb07IX9igq49Hr3w">友情链接</a>-->
<!--          </span>-->
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo menu"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="/Home/index">主页</el-menu-item>
<!--          <el-menu-item index="/News/index">新闻动态</el-menu-item>-->
          <el-menu-item index="/Team/index">团队</el-menu-item>
          <el-menu-item index="/Navigation/index">骑行导览</el-menu-item>
          <el-submenu index="2">
            <template slot="title">帮助中心</template>
            <el-menu-item index="ps">拍摄转码压缩指南</el-menu-item>
            <el-menu-item index="zm">转码软件包</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">主题</template>
            <el-menu-item index="dark">夜间模式</el-menu-item>
            <el-menu-item index="light">日间模式</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">个人中心</template>
            <el-menu-item index="LogOut">{{
              isdl ? "退出登录" : "用户登录"
            }}</el-menu-item>
            <el-menu-item index="/Manage/index">进入用户中心</el-menu-item>
            <!-- <el-menu-item index="4-3">上传视频</el-menu-item> -->
          </el-submenu>
        </el-menu>
      </div>
    </div>

    <transition>
      <router-view />
    </transition>
    <el-backtop
      :bottom="40"
      style="left: 40px; background-color: transparent; color: #d14649; font-size: 50px;"
    >
      <i class="el-icon-arrow-up"> </i>
    </el-backtop>
    <div class="ly" @click="send">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        style="border-radius: 0px; fill: rgb(14, 9, 43); width: 32px; height: 32px; margin-top:15px; flex-shrink: 0;"
      >
        <path
          d="M16 2C8.28 2 2 7.8 2 14.93a12.144 12.144 0 001.696 6.15l-1.668 7.51A1.16 1.16 0 003.63 29.9l6.914-3.072A14.835 14.835 0 0016 27.861c7.72 0 14-5.8 14-12.93S23.72 2 16 2zm4.508 16.32h-9.016a1.16 1.16 0 010-2.32h9.016a1.16 1.16 0 010 2.32zm0-4.638h-9.016a1.16 1.16 0 010-2.318h9.016a1.16 1.16 0 110 2.318z"
          fill="currentColor"
          fill-rule="nonzero"
        ></path>
      </svg>
    </div>
    <send ref="send"></send>
  </div>
</template>

<script>
import send from "@/components/send.vue";
import { schoolsTop,addViews } from "@/api/Mange/Mange";

import { getUser, setUser } from "@/utils/storage";

export default {
  components: {
    send,
  },
  data() {
    return {
      schoolsTop:[],
      activeIndex: "/Home/index",
      isdl: null,
    };
  },
  created() {
    this.isdl = getUser();
    this.init();
  },
  watch: {
    $route: {
      handler(newRouter) {
        this.activeIndex = newRouter.path;
      },
      immediate: true,
    },
  },
  methods: {
    yxChange(row) {
      row.id = row.value;
      row.name = row.label;
      addViews(row.id);
      if (row.hasVideo) {
        this.$router.push({
          path: "/Campus/" + row.id,
        });
      }else{
        this.$router.push({
          path: "/404"
        });
      }
    },
    init() {
      schoolsTop().then((res) => {
        if (res.status) {
          this.schoolsTop = res.data;
        } else {
          this.$message("获取校园浏览量排行错误！");
        }
        console.log(res);
      });
    },
    send() {
      this.$refs.send.showDialog();
    },
    baseUrl() {
      // 开发环境调试时改成服务器地址
      // 打包提交时改成window.location.origin
      if (process.env.NODE_ENV === "development") {
        // 开发环境
        return process.env.VUE_APP_BASE_API;
      } else {
        return window.location.origin;
      }
    },
    handleSelect(val) {
      if (val == "zm") {
        var a = document.createElement("a"); // 创建一个<a></a>标签
        a.href =
          "https://vr-campus.oss-cn-beijing.aliyuncs.com/web/ffmpeg-2021-12-27.rar";
        a.download = "ffmpeg-2021-12-27.rar"; // 设置下载文件文件名
        a.style.display = "none"; // 隐藏a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
      } else if (val == "ps") {
        var a = document.createElement("a"); // 创建一个<a></a>标签
        a.href =
          "https://vr-campus.oss-cn-beijing.aliyuncs.com/web/%E6%A0%A1%E5%9B%AD%E8%99%9A%E6%8B%9F%E5%AF%BC%E8%A7%88%E8%A7%86%E9%A2%91%E6%8B%8D%E6%91%84%E8%AE%BE%E5%A4%87%E5%8F%8A%E8%BD%AC%E7%A0%81%E5%92%8C%E5%8E%8B%E7%BC%A9%E6%8C%87%E5%8D%97%283%29.pdf";
        a.download = "校园虚拟导览视频拍摄设备及转码和压缩指南.pdf"; // 设置下载文件文件名
        a.style.display = "none"; // 隐藏a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
      } else if (val == "dark" || val == "light") {
        document.querySelector("body").setAttribute("id", val);
      } else if (val == "/Manage/index" && !getUser()) {
        this.$router.push("/Login");
      } else if (val == "LogOut") {
        if (this.isdl) {
          setUser("");
          this.isdl = true;
          window.localStorage.removeItem("user");
          this.$router.push("/Home/index");
        } else {
          this.$router.push("/Login");
        }
      } else {
        this.$router.push(val);
      }
    },
    menuJump(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>
<style lang="less">
.ly {
  width: 64px;
  height: 64px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #d14649;
  color: #000;
  font-size: 30px;
  text-align: center;
  line-height: 64px;
  z-index: 999;
}
.navbar {
  background-color: #0e092b;
  height: 60px;
  overflow: hidden;
  line-height: 60px;

  .inner {
    width: 1400px;
    margin: auto;
  }
  .log {
    float: left;
    color: #d14649;
  }
  .el-menu.el-menu--horizontal {
    background-color: transparent;
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none;
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
  .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
    color: #fff;
    background-color: transparent;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    color: #fff;
    border-bottom: none;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #d14649;
  }
  .menu {
    float: right;
    li {
      margin-right: 0 !important;
    }
  }
  .el-menu--horizontal > .el-menu-item {
    color: #d14649;
  }
}
</style>
