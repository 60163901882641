import { Service } from "@/http";
import qs from 'qs'
const api = {
  doLogin: "/api/doLogin",
  register: "/api/register",
  retrievePassword: "/api/retrievePassword",
  phoneCode: "/api/phoneCode",
  videosManageList: "/api/videos/manage/list",
  videosManageUpdate: "/api/videos/manage/update",
  messages: "api/messages",
  userList: "api/user/list",
  userUpdateNickName: "api/user/updateUsername",
  videos: "api/videos",
  upload: "api/upload",
  updatePassword: "api/user/updatePassword",
  schoolsOptions: "api/schools/options",
  userAuth: "api/user/auth",
  hasFirst: 'api/user/hasFirst',
  videosManageDelete:'api/videos/manage/delete',
  videosManageRevoke:'api/videos/manage/revoke',
  videosManageAudit:'api/videos/manage/audit',
  able:'api/user/able',
  unable:'api/user/unable',
  setAdmin:'api/user/setAdmin',
  setFirst:'api/user/setFirst',
  userOptions:'api/user/options',
  userUpdatePhone:'api/user/updatePhone',
  updateEmail:'api/user/updateEmail',
  applySetFirst:'api/user/applySetFirst',

  schoolList: 'api/schools/manage/list',
  saveSchool: 'api/schools/manage/save',
  setHot: 'api/schools/manage/setHot',
  getSchool: 'api/schools',
  areaOptions: 'api/areas',
  queryByAreaName:'api/schools/queryByAreaName',
  videosOptions:'api/videos/options',
  videosDetail:'api/videos/detail',
  comments:'api/comments',
  getUploadConfig: 'api/getUploadConfig',
  videosNewest:'api/videos/newest',
  schoolsHotsapi:'api/schools/hots',
  areas:'api/areas',
  videosAddLike:'api/videos/addLike',
  videosAddPlay:'api/videos/addPlay',
  qa:'api/qa',
  addViews:'api/schools/addViews',
  schoolsTop:'api/schools/top',
  qaListApi:'api/qa',
  settingsDetail:'api/settings/detail',
  userAgreement:'api/settings/userAgreement',
  settingsSave:'api/settings/save',

  articles: 'api/article/list',
  articleDetail: 'api/article/detail',
  articleAddViews: 'api/article/views',
  articleList: 'api/article/manage/list',
  articleSave: 'api/article/manage/save',
  articlePublish: 'api/article/manage/publish',
  articleCancelPublish: 'api/article/manage/cancelPublish',
  articleDelete: 'api/article/manage/delete',


};
//静态登录
export function doLogin(data) {
  return Service({
    url: api.doLogin,
    method: "post",
    data,
  });
}
//注册
export function register(data) {
  return Service({
    url: api.register,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data,
  });
}
//忘记密码
export function retrievePassword(data) {
  return Service({
    url: api.retrievePassword,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data,
  });
}
//发送短信验证码
export function phoneCode(data) {
  return Service({
    url: `${api.phoneCode}?phone=${data}`,
    method: "get",
  });
}
//视频列表
export function videosManageList(data) {
  return Service({
    url: `${api.videosManageList}?pageNo=${data.pageNo}&size=${data.size}`,
    method: "get",
  });
}
//视频修改
export function videosManageUpdate(data) {
  return Service({
    url: `${api.videosManageUpdate}?`,
    method: "post",
    data
  });
}
//站内信列表
export function messages(data) {
  return Service({
    url: `${api.messages}?pageNo=${data.pageNo}&size=${data.size}`,
    method: "get",
  });
}
//站内信列表
export function userList(data) {
  return Service({
    url: `${api.userList}?pageNo=${data.pageNo}&size=${data.size}`,
    method: "get",
  });
}
//更新昵称
export function userUpdateNickName(data) {
  return Service({
    url: api.userUpdateNickName,
    method: "post",
    data,
  });
}
//修改密码
export function updatePassword(data) {
  return Service({
    url: api.updatePassword,
    method: "post",
    data,
  });
}
// up视频上传
export function videos(data) {
  return Service({
    url: api.videos,
    method: "post",
    data,
  });
}
// 文件上传
export function upload(data) {
  return Service({
    url: api.upload,
    method: "post",
    data,
  });
}
// 学校下拉选择查询
export function schoolsOptions() {
  return Service({
    url: api.schoolsOptions,
    method: "get",
  });
}
// 个人信息认证
export function userAuth(data) {
  return Service({
    url: api.userAuth,
    method: "post",
    data,
  });
}
//视频列表删除
export function videosManageDelete(data) {
  return Service({
    url: `${api.videosManageDelete}?videoId=${data}`,
    method: "DELETE"
  });
}

// 视频恢复
export function videosManageRevoke(data) {
  return Service({
    url: `${api.videosManageRevoke}?videoId=${data}`,
    method: "POST"
  });
}
//视频列表审核
export function videosManageAudit(videoId, status, reasion) {
  return Service({
    url: api.videosManageAudit + "?id=" + videoId + "&pass=" + status + "&unpassReason=" + reasion,
    method: "post",
  });
}

// 启用用户
export function able(userId) {
  return Service({
    url: api.able + "?userId=" + userId,
    method: "post"
  });
}
// 禁用用户
export function unable(userId) {
  return Service({
    url: api.unable + "?userId=" + userId,
    method: "post"
  });
}

// 设置超管用户
export function setAdmin(userId) {
  return Service({
    url: api.setAdmin + "?userId=" + userId,
    method: "post"
  });
}

// 设置初创用户
export function setFirst(userId, pass) {
  return Service({
    url: api.setFirst + "?userId=" + userId + "&pass=" + pass,
    method: "post"
  });
}

//发送站内信
export function sendmessages(data) {
  return Service({
    url: `${api.messages}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data:qs.stringify(data),
  });
}
//站内信列表
export function userOptions() {
  return Service({
    url: `${api.userOptions}`,
    method: "get",
  });
}

//修改手机号
export function userUpdatePhone(data) {
  return Service({
    url: `${api.userUpdatePhone}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data:qs.stringify(data),
  });
}
//修改邮箱
export function updateEmail(data) {
  return Service({
    url: `${api.updateEmail}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data:qs.stringify(data),
  });
}

//学校是否有初创了
export function hasFirst(schoolId) {
  return Service({
    url: `${api.hasFirst}?schoolId=${schoolId}`,
    method: "get",
  });
}

//学校是否有初创了
export function applySetFirst() {
  return Service({
    url: `${api.applySetFirst}`,
    method: "post",
  });
}
// 学校管理
export function schoolList(data) {
  return Service({
    url: `${api.schoolList}?pageNo=${data.pageNo}&size=${data.size}&name=${data.name}`,
    method: "get",
  });
}

// 学校管理
export function saveSchool(data) {
  return Service({
    url: `${api.saveSchool}`,
    method: "post",
    data,
  });
}

// 学校管理
export function setHot(data) {
  return Service({
    url: `${api.setHot}`,
    method: "post",
    data,
  });
}

// 学校管理
export function getSchoolById(schoolId) {
  return Service({
    url: `${api.getSchool}/${schoolId}`,
    method: "get",
  });
}

// 地区
export function areaList() {
  return Service({
    url: api.areaOptions,
    method: "get",
  });
}

// 根据地区名称查询所有学校
export function queryByAreaName(data) {
  return Service({
    url: `${api.queryByAreaName}?areaName=${data}`,
    method: "get",
  });
}

// 导览页面过滤学校视频列表
export function videosOptions(data) {
  return Service({
    url:`${api.videosOptions}?schoolId=${data.schoolId}&isCollege=${data.isCollege}&isCycling=${data.isCycling}&key=${data.key}`,
    method: "get",
  });
}
// 视频详情
export function videosDetail(data) {
  return Service({
    url:`${api.videosDetail}?videoId=${data}`,
    method: "get",
  });
}
// 视频评论列表
export function comments(data) {
  return Service({
    url:`${api.comments}?pageNo=${data.pageNo}&size=${data.size}&videoId=${data.videoId}`,
    method: "get",
  });
}
// 保存视频评论列表
export function saveComments(data) {
  return Service({
    url:`${api.comments}`,
    method: "post",
    data,
  });
}
// 最新上线
export function videosNewest() {
  return Service({
    url: `${api.videosNewest}`,
    method: "get",
  });
}
// 获取热点学校
export function schoolsHotsapi() {
  return Service({
    url: `${api.schoolsHotsapi}?size=10`,
    method: "get",
  });
}

// 获取上传配置
export function getUploadConfig() {
  return Service({
    url: api.getUploadConfig,
    method: "get",
  });
}
// 获取所有省区域
export function areas() {
  return Service({
    url: api.areas,
    method: "get",
  });
}
// 视频点赞
export function videosAddLike(data) {
  return Service({
    url: `${api.videosAddLike}?videoId=${data}`,
    method: "get",
  });
}


// 增加播放量
export function videosAddPlay(data) {
  return Service({
    url: `${api.videosAddPlay}?videoId=${data}`,
    method: "get",
  });
}
// 增加浏览量
export function addViews(data) {
  return Service({
    url: `${api.addViews}?schoolId=${data}`,
    method: "get",
  });
}
//视频浏览量排行
export function schoolsTop() {
  return Service({
    url: `${api.schoolsTop}`,
    method: "get",
  });
}

//发送信息
export function qa(data) {
  return Service({
    url: `${api.qa}`,
    method: "post",
    data
  });
}

// 文章管理
export function articleList(data) {
  return Service({
    url: `${api.articleList}?pageNo=${data.pageNo}&size=${data.size}&title=${data.title}`,
    method: 'get'
  })
}
export function articles(data) {
  return Service({
    url: `${api.articles}?pageNo=${data.pageNo}&size=${data.size}`,
    method: 'get'
  })
}
export function articleDetail(id) {
  return Service({
    url: `${api.articleDetail}?id=${id}`,
    method: 'get'
  })
}
export function articleAddViews(id) {
  return Service({
    url: `${api.articleAddViews}?id=${id}`,
    method: 'get'
  })
}
export function articleSave(data) {
  return Service({
    url: `${api.articleSave}`,
    method: 'post',
    data: data
  })
}
export function articlePublish(id) {
  return Service({
    url: `${api.articlePublish}?id=${id}`,
    method: 'post',
  })
}
export function articleCancelPublish(id) {
  return Service({
    url: `${api.articleCancelPublish}?id=${id}`,
    method: 'post'
  })
}
export function articleDelete(id) {
  return Service({
    url: `${api.articleDelete}?id=${id}`,
    method: 'post'
  })
}

// 反馈信息管理
export function qaList(data) {
  return Service({
    url: `${api.qaListApi}?pageNo=${data.pageNo}&size=${data.size}`,
    method: "get",
  });
}
export function deleteQa(id) {
  return Service({
    url: `${api.qaListApi}?id=`+id,
    method: "delete",
  });
}

// 系统设置相关
export function settingsDetail() {
  return Service({
    url: `${api.settingsDetail}`,
    method: "get",
  });
}

export function userAgreement() {
  return Service({
    url: `${api.userAgreement}`,
    method: "get",
  });
}
export function settingsSave(data) {
  return Service({
    url: `${api.settingsSave}`,
    method: "post",
    data
  });
}
