<template>
  <div class="login">
    <div class="main">
      <h3>用户登录</h3>
      <p class="title">用户名或手机号</p>
      <input class="input" v-model="ruleForm.username" placeholder="请输入用户名或手机号"/>
      <p class="title">密码</p>
      <input class="input" type="password" v-model="ruleForm.password" placeholder="请输入密码"/>
      <div>
        <a href="#" class="btn1" @click="jump('/Retrieve')">找回密码</a>
        <a v-if="systemSettins.openRegister" href="#" class="btn2" @click="jump('/Register')">注册账号</a>
        <a class="btn" @click="submitForm">登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { doLogin } from "@/api/Login/Login";
import { setUser } from "@/utils/storage";
import { settingsDetail } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      systemSettins: {},
      ruleForm: {
        password: "",
        username: "",
      },
    };
  },
  created() {
    settingsDetail().then(res => {
      this.systemSettins = res.data;
    })
  },
  methods: {
    handleSelect(var1, var2) {
      this.$router.push(var1);
    },
    jump(path) {
      this.$router.push(path);
    },
    submitForm() {
      // || this.ruleForm.username.length != 11
      if (this.ruleForm.username.trim() == "" ) {
        this.$message("请输入手机号");
      } else if (this.ruleForm.password.trim() == "") {
        this.$message("请输入密码");
      } else {
        this.staticlogin();
      }
    },
    staticlogin() {
      doLogin(this.ruleForm)
        .then((res) => {
          if (res.status) {
            setUser(res.data);
            this.$router.push("/Manage/index");
            return;
          } else {
            this.$message(res.msg);
          }
        })
        .catch(() => {
          this.$message("获取服务器认证失败");
        }); //捕获异常
    },
  },
};
</script>

<style lang="less">
.login {
  background-color: #0e092b;
  height: 100%;
  .main {
    width: 300px;
    height: 300px;
    border: 1px solid #000;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
    padding-left: 30px;
    h3 {
      color: #fff;
      font-size: 36px;
      font-weight: normal;
      text-align: center;
      padding: 20px;
    }
    .title {
      padding: 5px 0;
      font-size: 14px;
      color: #fff;
    }
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 280px;
      height: 20px;
    }
    .btn1 {
      color: #d14649;
      font-size: 14px;
      display: inline-block;
      line-height: 60px;
    }
    .btn2 {
      color: #d14649;
      font-size: 14px;
      display: inline-block;
      margin-left: 20px;
      line-height: 60px;
    }
    .btn {
      width: 80px;
      height: 20px;
      font-size: 14px;
      background-color: #d14649;
      color: #3e3131;
      text-align: center;
      line-height: 20px;
      display: block;
      margin: 0 auto;
      float: right;
      margin-top: 21px;
      margin-right: 20px;
    }
  }
}
</style>
