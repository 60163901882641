const TokenKey = 'token'
const UserKey = 'userInfo'
import store from '../store'

// token
export function getToken() {
  return window.localStorage.getItem(TokenKey);
}

export function setToken(token) {
  store.commit('SET_TOKEN', token);
  return window.localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return window.localStorage.removeItem(TokenKey);
}
// user
export function getUser() {
  return store.state.user.user || JSON.parse(window.localStorage.getItem('user'))
}

export function isLogin() {
  return !!window.localStorage.getItem('user');
}

export function setUser(user) {
  store.commit('SET_USER', user);
  window.localStorage.setItem('user',JSON.stringify(user));
}
export function updateUser(key, value) {
  var new_user = window.localStorage.getItem('user');
  if(!new_user) return false;
  new_user = JSON.parse(new_user);
  new_user[key] = value;
  store.commit('SET_USER', new_user);
  return window.localStorage.setItem('user', JSON.stringify(new_user));
}
export function removeUser() {
  return window.localStorage.removeItem('user')
}
