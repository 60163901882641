import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Service } from "@/http.js";
import store from "./store";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import './assets/font/font.css'

// import './styles/element-variables.scss'
Vue.use(Element);

Vue.prototype.$axios = Service;
//ui框架

//日期中文全局配置

//引入统计图插件
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
