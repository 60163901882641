<template>
  <div class="Mangebg">

      <div class="Mangenavbar">
        <div class="inner">
          <div class="log">
            <router-link :to="'/Home/index'" style="color:#d14649; text-decoration: none;">VR-CAMPUS TOUR</router-link>
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo menu"
            mode="horizontal"
            @select="handleSelect"
          >
            <!-- <el-menu-item index="/Login/index">登录</el-menu-item>

            <el-menu-item index="2">用户管理</el-menu-item>
            <el-menu-item index="3">信息</el-menu-item> -->
            <el-submenu index="4">
              <template slot="title">个人中心</template>
              <el-menu-item index="LogOut">退出登录</el-menu-item>
              <el-menu-item index="/Manage/index">进入用户中心</el-menu-item>
              <!-- <el-menu-item index="4-3">上传视频</el-menu-item> -->
            </el-submenu>
          </el-menu>

        </div>
      </div>

    <router-view />
  </div>
</template>

<script>
import { getUser,setUser } from "@/utils/storage";

export default {
  data() {
    return {
      activeIndex: "/Home/index",
      user: {}
    };
  },
  created() {
    this.user = getUser().user;
  },
  methods: {
    handleSelect(val) {
      if (val == "/Manage/index"&&!getUser()) {
        this.$router.push("/Login");
      }else if (val == "LogOut") {
        setUser('');
        window.localStorage.removeItem('user')
        this.$router.push("/Home/index");
      } else {
        this.$router.push(val);
      }
    }
  },
};
</script>
<style lang="less">
.Mangenavbar {
  background-color: #0e092b;
  height: 60px;
  line-height: 60px;
  .inner{ width:1400px; margin:auto}
  .log {
    float: left;
    color: #d14649;
  }
  .el-menu.el-menu--horizontal {
    background-color: transparent;
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none;
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
  .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
    color: #fff;
    background-color: transparent;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #fff;
  }
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    color: #fff;
    border-bottom: none;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #d14649;
  }
  .el-menu--horizontal>.el-menu-item{
    color: #d14649;

  }
  .menu {
    float: right;
    li {
      margin-right: 0 !important;

    }
  }
}
</style>
