import { getUser } from '@/utils/storage'

const user = {
    state: {
        token: '',
        user: '',
        loading:false

    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USER: (state, user) => {
            state.user = user
        },
        SET_LOADING: (state, loading) => {
            state.loading = loading
        }
    },

    actions: {
        // 获取用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                var userInfo = getUser();
                if (userInfo) {
                    commit('SET_USER', JSON.parse(userInfo));
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        },
        // 清除
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_USER', '')
                commit('SET_LOADING', '')
                window.localStorage.clear();
                resolve()
            })
        }
    }
}

export default user
