<template>
    <div class="sendInfo">
      <el-dialog
        title="发送信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <el-input class="textarea" type="text" v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
        <br>
        <br>
        <el-input class="textarea" type="textarea" v-model="ruleForm.content" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="submitForm">发送</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { qa} from "@/api/Mange/Mange";
  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          phone:"",
          content:"",
        },
      };
    },
    created() {
  
    },
    methods: {
      init(){
        this.ruleForm={
          phone:"",
          content:"",
        }
   
      },
      handleClose() {
        this.dialogVisible = false;
      },
      showDialog() {
        this.init()
        this.dialogVisible = true;
  
      },
      submitForm() {
        if (this.ruleForm.phone.trim() == ""||this.ruleForm.phone.length!=11) {
          this.$message("请输入手机号");
        }else if (this.ruleForm.content.trim() == "") {
          this.$message("请输入站内信息");
        } else {
          qa(this.ruleForm)
            .then((res) => {
              if (res.status) {
                this.$message("发送成功");
                this.$emit("calback");
              }
              this.dialogVisible = false;
            })
            .catch(() => {
              this.$toast("获取服务器认证失败");
            }); //捕获异常
        }
      },
    },
  };
  </script>
  
  <style lang="less">
  .sendInfo {
  
      p {
        color: #fff;
        line-height: 30px;
      }
      .el-select {
        width: 100%;
      }
      .el-input__inner {
        border: 1px solid #333;
        background-color: #0e092b;
      }
  
    .el-button {
      background-color: #d14649;
      color: #3e3131;
      border: none;
    }
    .el-textarea__inner {
      padding: 5px 15px;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 100%;
      height: 80px;
    }
  
  }
  </style>
  