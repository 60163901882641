import axios from 'axios'
import {Message } from 'element-ui'
import {setUser } from "@/utils/storage";

export const Service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000 // request timeout
})
// 添加请求拦截器
Service.interceptors.request.use(
  config => {
  
    return config
  },
  error => {
    return Promise.reject(error);
}
)
// 添加响应拦截器
Service.interceptors.response.use(response => {
  if(response.data.code==555){
    Message({
      message: response.data.msg,
    })
    setUser("");
    window.localStorage.removeItem("user");
    window.location.hash = `#/login`
  }
  return response.data
}, error => {
  return Promise.reject(error)
})