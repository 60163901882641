import Vue from "vue";
import Router from "vue-router";
import Layout from "../views/Layout.vue";
import MangeLayout from "../views/MangeLayout.vue";

import Login from "../views/Login.vue";
import { getUser } from "@/utils/storage";

Vue.use(Router);

var router = new Router({
  routes: [
    {
      path: "/",
      name: "index",
      component: Layout,
      redirect: "/Home/index",
      children: [
        {
          path: "/Home/index",
          name: "主页",
          component: () => import("@/views/Home/index"),
        },
        {
          path: "/News/index",
          name: "新闻动态",
          component: () => import("@/views/News/index"),
        },
        {
          path: "/News/detail/:id",
          name: "新闻",
          component: () => import("@/views/News/detail"),
        },
        {
          path: "/Team/index",
          name: "团队",
          component: () => import("@/views/Team/index"),
        },
        {
          path: "/Navigation/index",
          name: "骑行导航",
          component: () => import("@/views/Navigation/index"),
        },
        {
          path: "/404",
          name: "404",
          component: () => import("@/views/404"),
        },
      ],
    },
    {
      path: "/Manage",
      name: "index",
      redirect: "/Manage/index",
      component: MangeLayout,
      children: [
        {
          path: "/Manage/index",
          name: "主页",
          component: () => import("@/views/Manage/index"),
        }
      ],
    },
    {
      path: "/Campus/:schoolId",
      name: "Campus",
      component: () => import("@/views/Campus"),
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
    },
    {
      path: "/Register",
      name: "Register",
      component: () => import("@/views/Register"),
    },
    {
      path: "/Retrieve",
      name: "Retrieve",
      component: () => import("@/views/Retrieve"),
    },


  ],
});
// 不重定向白名单
const whiteList = ['/Manage/index'];
// 路由守卫设置
router.beforeEach(function (to, from, next) {
  if (getUser()) {
    next()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next('/Login');
    }else{
      next()
    }
  }
});

export default router;
