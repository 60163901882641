import { Service } from "@/http";
import qs from 'qs'

const api = {
  doLogin: "/api/doLogin",
  register: "/api/register",
  retrievePassword:"/api/retrievePassword",
  phoneCode:"/api/phoneCode",
};
//静态登录
export function doLogin(data) {
  return Service({
    url: api.doLogin,
    method: "post",
    data,
  });
}
//注册
export function register(data) {
  return Service({
    url: api.register,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data:qs.stringify(data)
  });
}
//忘记密码
export function retrievePassword(data) {
  return Service({
    url: api.retrievePassword,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
    data:qs.stringify(data),
  });
}
//发送短信验证码
export function phoneCode(data) {
  return Service({
    url: `${api.phoneCode}?phone=${data}`,
    method: "get"
  });
}